/**
 * @file AuthContext.tsx
 * @description Contexte pour la gestion de l'authentification, incluant la gestion des utilisateurs, la connexion, la déconnexion, et la réinitialisation du mot de passe.
 */
import {
  useState,
  createContext,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosInterceptor";
import { HttpStatusCode } from "axios";
import { toast } from "react-toastify";
import { message } from "antd";

/**
 * @interface User
 * @description Représente un utilisateur authentifié avec ses informations.
 */
export interface User {
  id: number;
  username: string;
  email: string;
  roles: string[];
  firstName: string;
  lastName: string;
}
/**
 * @interface Cabinet
 * @description Représente les informations d'un cabinet.
 */
export interface Cabinet {
  id: string;
  companyName: string;
  siren: string;
  nic: string;
}
/**
 * @interface CompanyResponseDto
 * @description Détails d'une entreprise retournée par l'API.
 */
export interface CompanyResponseDto {
  id: string;
  siren: string;
  nic: string;
  numDossier: string;
  raisonSociale: string;
  telephone: string;
  isir: string;
  codeNaf: string;
  dateEchTva: number;
  missionPres: boolean;
  typeComptabilite: string;
  debutExercice: Date;
  finExercice: Date;
  nomMandataire: string;
  prenomMandataire: string;
  caisseCongePaye: boolean;
  emailContact: string;
  refFiscale: string;
  planComptable: string;
  regimeTva: string;
  address: Address;
  legalCategory: LegalCategory;
  cabinetId: string;
  collaboratorIds: number[];
}
/**
 * @interface Address
 * @description Adresse liée à une entreprise ou un cabinet.
 */
export interface Address {
  id: string;
  rueEntreprise: string;
  numeroRue: string;
  complementAdresse: string;
  lieuDit: string;
  ville: string;
  codePostal: string;
  pays: string;
}
/**
 * @interface LegalCategory
 * @description Représente la catégorie légale d'une entreprise.
 */
export interface LegalCategory {
  id: string;
  categoryCode: string;
  categoryLib: string;
}
/**
 * @interface AuthContextType
 * @description Interface pour le contexte d'authentification.
 */
export interface AuthContextType {
  loading: boolean;
  error: string | null;
  isAuthenticated: boolean;
  isAdmin: boolean;
  user: User | undefined;
  userCabinet?: Cabinet;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
  resetPassword: (
    email: string
  ) => Promise<{ success: boolean; message: string }>;

  validateResetToken: (email: string, token: string) => Promise<boolean>;
  updatePassword: (
    email: string,
    token: string,
    newPassword: string
  ) => Promise<{ success: boolean; message: string }>;
  email: string;
  setEmail: (email: string) => void;
  token: string | null;
  setToken: (token: string | null) => void;
  registerClient: (
    email: string,
    firstName: string,
    lastName: string,
    companyId: string
  ) => void;
  getCompanyByUserId: (userId: number) => Promise<CompanyResponseDto | null>;
  clientCabinetId?: string;
  setClientCabinetId?: (cabinetId: string) => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>(() => {
    // Initialize user from localStorage if available
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setIsAuthenticated(true);
    }
    return storedUser ? JSON.parse(storedUser) : undefined;
  });
  // useState `userCabinet` pour stocker les informations du cabinet de l'utilisateur du Role client
  // L'état est initialisé à partir des données présentes dans le localStorage, si disponibles
  const [userCabinet, setUserCabinet] = useState<Cabinet | undefined>(() => {
    // Initialize user from localStorage if available
    const storedCabinet = localStorage.getItem("cabinet");
    if (storedCabinet) {
      setIsAuthenticated(true);
    }
    return storedCabinet ? JSON.parse(storedCabinet) : undefined;
  });

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const [email, setEmailState] = useState<string>(() => {
    return localStorage.getItem("resetEmail") || "";
  });
  const [token, setTokenState] = useState<string | null>(() => {
    return localStorage.getItem("resetToken") || null;
  });
  const [clientCabinetId, setClientCabinetId] = useState<string | undefined>(
    () => {
      const storedClientCabinetId = localStorage.getItem("clientCabinetId");
      return storedClientCabinetId ? storedClientCabinetId : undefined;
    }
  );
  const navigate = useNavigate();
  /**
   * @function setEmail
   * @description Met à jour l'email pour la réinitialisation du mot de passe et le stocke dans localStorage.
   * @param {string} newEmail - L'adresse email à définir.
   * @returns {void}
   */
  const setEmail = (newEmail: string) => {
    setEmailState(newEmail);
    if (newEmail) {
      localStorage.setItem("resetEmail", newEmail);
    } else {
      localStorage.removeItem("resetEmail");
    }
  };
  /**
   * @function setToken
   * @description Définit un jeton de réinitialisation du mot de passe et le stocke dans localStorage.
   * @param {string | null} newToken - Le jeton à définir.
   * @returns {void}
   */
  const setToken = (newToken: string | null) => {
    setTokenState(newToken);
    if (newToken) {
      localStorage.setItem("resetToken", newToken);
    } else {
      localStorage.removeItem("resetToken");
    }
  };
  /**
   * @function fetchCabinet
   * @description Récupère les informations d'un cabinet par son UUID.
   * @param {string} uuid - L'identifiant du cabinet.
   * @returns {Promise<Cabinet | null>} - Le cabinet correspondant ou `null` en cas d'échec.
   */
  const fetchCabinet = async (uuid: string): Promise<Cabinet | null> => {
    try {
      const response = await axiosInstance.get(`api/cabinets/${uuid}`);
      if (response.status === HttpStatusCode.Ok) {
        const data = response.data;
        return {
          id: data.id,
          siren: data.siren,
          nic: data.nic,
          companyName: response.data.companyName,
        };
      }
    } catch (error) {
      console.error("Echec de récupération de cabinet.", error);
    }
    return null;
  };
  /**
   * @function login
   * @description Gère la connexion de l'utilisateur et met à jour le contexte d'authentification.
   * @param {string} email - L'adresse email de l'utilisateur.
   * @param {string} password - Le mot de passe de l'utilisateur.
   * @returns {Promise<boolean>} - Retourne `true` si la connexion est réussie, sinon `false`.
   */
  const login = async (email: string, password: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.post(`api/auth/signin`, {
        email,
        password,
      });
      console.log("Login successful:" + JSON.stringify(response.data.response));

      const accessToken = response.data.response["accessToken"];
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem(
        "refreshToken",
        response.data.response["refreshToken"]
      );

      const loggedUser = {
        email: response.data.response["email"],
        id: response.data.response["id"],
        username: response.data.response["username"],
        firstName: response.data.response["firstName"],
        lastName: response.data.response["lastName"],
        roles: response.data.response["roles"],
      };
      const userId = loggedUser.id;
      const cabinetsIds = response.data.response["cabinets"];
      if (cabinetsIds && cabinetsIds.length > 0) {
        const cabinet = await fetchCabinet(cabinetsIds[0]);
        if (cabinet) {
          console.log("User cabinets : " + JSON.stringify(cabinet));
          localStorage.setItem("cabinet", JSON.stringify(cabinet));
          setUserCabinet(cabinet);
        }
      }

      setIsAuthenticated(true);
      setUser(loggedUser);
      localStorage.setItem("user", JSON.stringify(loggedUser));

      if (loggedUser.roles.includes("ROLE_ADMIN_APPLICATION")) {
        setIsAdmin(true);
        navigate("/admin");
      } else if (loggedUser.roles.includes("ROLE_COLLABORATEUR_CABINET")) {
        navigate("/cabinet");
      } else if (loggedUser.roles.includes("ROLE_CLIENT_CABINET")) {
        // Fetch the company details by userId
        const company = await getCompanyByUserId(userId);
        if (company) {
          // Set the clientCabinetId
          setClientCabinetId(company.cabinetId);
          localStorage.setItem("clientCabinetId", company.cabinetId);

          navigate("/cabinet/tableau-de-bord", {
            state: { company },
          });
        }
      }
      return true;
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
      setLoading(false);
    }
    return false;
  };
  /**
   * @function logout
   * @description Déconnecte l'utilisateur en supprimant les informations de session et redirige vers la page d'authentification.
   * @returns {void}
   */
  const logout = () => {
    // Met à jour l'état d'authentification avant la redirection.
    setIsAuthenticated(false);
    setIsAdmin(false);
    setUser(undefined);
    // Retire les informations de session du localStorage.
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    localStorage.removeItem("cabinet");
    localStorage.removeItem("resetEmail"); // Clear resetEmail on logout
    // Redirige l'utilisateur vers la page d'authentification (/auth)
    navigate("/auth");
  };
  /**
   * @function resetPassword
   * @description Envoie une demande de réinitialisation de mot de passe.
   * @param {string} email - L'adresse email pour laquelle réinitialiser le mot de passe.
   * @returns {Promise<{ success: boolean; message: string }>} - Retourne un objet indiquant le succès de la réinitialisation.
   */
  const resetPassword = async (
    email: string
  ): Promise<{ success: boolean; message: string }> => {
    try {
      const response = await axiosInstance.post(`/api/auth/password-reset`, {
        email,
      });
      console.log("Response from reset-password:", response.data);

      if (response.status === HttpStatusCode.Ok && response.data.success) {
        console.log("Password reset successful:", response.data);
        setError(null);
        return {
          success: true,
          message:
            response.data.message ||
            "Un lien de réinitialisation a été envoyé à votre adresse e-mail.",
        };
      } else {
        const errorMessage = response.data.message || "Une erreur est survenue";
        console.log("Password reset failed with message:", errorMessage);
        setError(errorMessage);
        return { success: false, message: errorMessage };
      }
    } catch (error) {
      console.error("Password reset request failed:", error);
      const errorMessage =
        "Une erreur est survenue lors de la demande de réinitialisation.";
      setError(errorMessage);
      return { success: false, message: errorMessage };
    }
  };
  /**
   * @function validateResetToken
   * @description Valide un jeton de réinitialisation du mot de passe pour un utilisateur.
   * @param {string} email - L'adresse email de l'utilisateur.
   * @param {string} token - Le jeton de réinitialisation.
   * @returns {Promise<boolean>} - Retourne `true` si le jeton est valide, sinon `false`.
   */
  const validateResetToken = async (
    email: string,
    token: string
  ): Promise<boolean> => {
    try {
      const response = await axiosInstance.post(`/api/auth/token-validate`, {
        email,
        token,
      });
      console.log("Response from validate-reset-token:", response.data);
      if (response.status === HttpStatusCode.Ok && response.data.success) {
        return true;
      } else {
        const errorMessage = response.data.message || "Invalid token or email.";
        console.log("Token validation failed with message:", errorMessage);
        setError(errorMessage);
        return false;
      }
    } catch (error) {
      console.error("Token validation failed:", error);
      setError("An error occurred during token validation.");
      return false;
    }
  };
  /**
   * @function updatePassword
   * @description Met à jour le mot de passe après validation du jeton.
   * @param {string} email - L'adresse email de l'utilisateur.
   * @param {string} token - Le jeton de réinitialisation.
   * @param {string} newPassword - Le nouveau mot de passe.
   * @returns {Promise<{ success: boolean; message: string }>} - Retourne un objet indiquant si le mot de passe a été mis à jour avec succès.
   */
  const updatePassword = async (
    email: string,
    token: string,
    newPassword: string
  ): Promise<{ success: boolean; message: string }> => {
    try {
      const response = await axiosInstance.post(`/api/auth/password-update`, {
        email,
        token,
        newPassword,
      });
      console.log("Response from update-password:", response.data);

      if (response.status === HttpStatusCode.Ok && response.data.success) {
        return { success: true, message: response.data.message };
      } else {
        const errorMessage =
          response.data.message || "Failed to update the password.";
        console.log("Password update failed with message:", errorMessage);
        setError(errorMessage);
        return { success: false, message: errorMessage };
      }
    } catch (error) {
      console.error("Password update failed:", error);
      setError("An error occurred during password update.");
      return { success: false, message: "An error occurred." };
    }
  };
  /**
   * @function registerClient
   * @description Enregistre un client pour un cabinet donné.
   * @param {string} email - L'adresse email du client.
   * @param {string} firstName - Le prénom du client.
   * @param {string} lastName - Le nom du client.
   * @param {string} companyId - L'identifiant de la société associée au client.
   * @returns {Promise<void>} - Retourne une promesse vide une fois le client enregistré.
   */
  const registerClient = async (
    email: string,
    firstName: string,
    lastName: string,
    companyId: string
  ) => {
    try {
      const response = await axiosInstance.post(`api/auth/client`, {
        cabinetId: userCabinet?.id,
        companyId: companyId,
        email: email,
        firstName: firstName,
        lastName: lastName,
      });

      message.success("Client créé avec succès");
      console.log("Client created successfully:", response.data);
      return response.data.response;
    } catch (error: any) {
      if (error.response?.status === HttpStatusCode.Conflict) {
        message.error("Ce client est déjà créé");
      } else {
        console.error("Failed to register client:", error);
        message.error("Échec de l'inscription du client");
      }
    }
  };
  /**
   * @function getCompanyByUserId
   * @description Récupère les informations d'une entreprise à partir de l'ID de l'utilisateur.
   * @param {number} userId - L'ID de l'utilisateur.
   * @returns {Promise<CompanyResponseDto | null>} - Retourne les détails de l'entreprise ou `null` en cas d'échec.
   */
  const getCompanyByUserId = async (
    userId: number
  ): Promise<CompanyResponseDto | null> => {
    console.log("Fetching company by userId:", userId);
    try {
      const response = await axiosInstance.get(`/api/auth/client/${userId}`);
      if (response.status === HttpStatusCode.Ok) {
        const company: CompanyResponseDto = response.data;
        console.log("Company fetched successfully:", company);
        return company;
      } else {
        console.error("Failed to fetch company for user:", userId);
        return null;
      }
    } catch (error) {
      console.error("Error fetching company by userId:", error);
      return null;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userCabinet,
        loading,
        error,
        isAdmin,
        isAuthenticated,
        email,
        token,
        clientCabinetId,
        setToken,
        setEmail,
        login,
        logout,
        resetPassword,
        validateResetToken,
        updatePassword,
        registerClient,
        getCompanyByUserId,
        setClientCabinetId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

//Task context
export interface Task {
  id: string;
  name: string;
  done: boolean;
}
/**
 * @function useAuth
 * @description Hook personnalisé pour accéder au contexte d'authentification.
 * @returns {AuthContextType} - Retourne le contexte d'authentification.
 * @throws {Error} - Lance une erreur si utilisé hors du `AuthProvider`.
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth doit etre utilisé sous AuthProvider");
  }
  return context;
};
