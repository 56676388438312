/**
 * @file axiosInstance.ts
 * @description Création d'une instance Axios pour faciliter les requêtes HTTP à l'API backend,
 * incluant la gestion des jetons d'accès et de rafraîchissement.
 */
import axios from "axios";
import { baseUrl } from "../context/env";

// Créer une instance Axios
const axiosInstance = axios.create({
  baseURL: baseUrl, // URL de base de votre API backend
  withCredentials: true, // Assurez-vous que les cookies sont envoyés avec les requêtes
});

// Intercepteur de requêtes pour attacher le header Authorization
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken"); // Supposons que le jeton d'accès soit stocké dans localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercepteur de réponses pour gérer la logique de rafraîchissement des jetons
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Vérifiez si l'erreur est liée à une expiration du jeton
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Effectuer une requête pour obtenir un nouveau jeton d'accès
        const refreshToken = localStorage.getItem("refreshToken");
        const { data } = await axios.post(
          baseUrl + "/api/auth/refresh-token",
          { refreshToken },
          { withCredentials: true }
        );
        localStorage.setItem("accessToken", data.accessToken); // Stocker le nouveau jeton d'accès
        axiosInstance.defaults.headers.common["Authorization"] =
          `Bearer ${data.accessToken}`;
        return axiosInstance(originalRequest); // Réessayer la requête initiale avec le nouveau jeton
      } catch (err) {
        // Gérer l'expiration du jeton de rafraîchissement ou d'autres erreurs
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
