/**
 * @file RouteConfig.tsx
 * @description Configuration des routes de l'application avec gestion des rôles, redirections et chargement paresseux.
 */

import { FC, Suspense, lazy, useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import RoleBasedRoute from "./RoleBasedRoute";
import { AuthContext } from "../context/AuthContext";

// Chargement paresseux des composants
const DashboardPage = lazy(() => import("../pages/Dashboard/Dashboard"));
const PointsSuspensionPage = lazy(
  () => import("../pages/PointsSuspens/PointsSuspensPage")
);
const QuestionnairePage = lazy(
  () => import("../pages/Questionnaire/QuestionnaireHoc")
);
const FecPage = lazy(() => import("../pages/FecData/FecPage"));
const FoldersPage = lazy(() => import("../pages/Company/CompanyListPage"));
const SearchCompanyPage = lazy(
  () => import("../pages/Company/SearchCompanyPage")
);
const AuthPage = lazy(() => import("../pages/Auth/AuthPage"));
const NotAuthorizedPage = lazy(() => import("../pages/Auth/NotAuthorized"));
const AdminPage = lazy(() => import("../pages/Admin/AdminPage"));
const CreateCabinetPage = lazy(
  () => import("../pages/Admin/CreateCabinetPage")
);
const AdminLayout = lazy(() => import("./AdminLayout"));
const CabinetLayout = lazy(() => import("./CabinetLayout"));
const AccountSettingsLayout = lazy(() => import("./AccountSettingsLayout"));
const ParticipantsAssignmentPage = lazy(
  () => import("../pages/Company/ParticipantsAssignmentPage")
); // Charge la page Assignations des intervenants
const ClientInviationPage = lazy(
  () => import("../pages/Company/ClientInvitationPage")
); //Charge la page invitation client
const MainDashboard = lazy(
  () => import("../components/MainDashboard/MainDashboard")
); //Charge la page Dashboard
const EditCompanyPage = lazy(() => import("../pages/Company/EditCompanyPage")); //Charge la page Ajouter entreprise
const AddCompanyPage = lazy(() => import("../pages/Company/AddCompanyPage"));
const AccountingSettingsPage = lazy(
  () => import("../pages/AccountingSettingsPage/AccountingSettingsPage") //Charge la page de parametrage comptabilite
);
const AccountingJournalsPage = lazy(
  () => import("../pages/AccountingJournalsPage/AccountingJournalsPage")
);
const AccountListPage = lazy(
  () => import("../pages/AccountListPage/AccountListPage")
);
const FolderPointSuspensPage = lazy(
  () => import("../pages/FolderPointSuspensPage/FolderPointSuspensPage")
);
const AccountMappingPage = lazy(
  () => import("../pages/AccountMappingPage/AccountMappingPage")
);
const JournalMatchingPage = lazy(
  () => import("../pages/JournalMatchingPage/JournalMatchingPage")
);
const FolderQuestionnairePage = lazy(
  () => import("../pages/FolderQuestionnairePage/FolderQuestionnairePage")
);
const FiscalSettingsPage = lazy(
  () => import("../pages/FiscalSettings/FiscalSettingsPage")
); //Charge la page Ajouter entreprise
const ResetPasswordPage = lazy(
  () => import("../pages/ResetPassword/ResetPasswordPage")
);
const ForgotPasswordEmailPage = lazy(
  () => import("../pages/ForgotPasswordEmail/ForgotPasswordEmailPage")
);
const TokenVerification = lazy(
  () => import("../pages/ResetPassword/TokenVerification")
);
const PiecesPage = lazy(() => import("../pages/PiecesPage/PiecesPage"));
const JounalPage = lazy(() => import("../pages/JournalPage/JournalPage"));
// Interface définissant la structure des routes
export interface AppRoute {
  path: string;
  component?: React.LazyExoticComponent<FC<any>>;
  requiredRole?: string[];
  children?: AppRoute[];
}

// Définition des routes avec les composants correspondants
const routes: AppRoute[] = [
  {
    path: "/auth",
    component: AuthPage,
  },
  {
    path: "/not-authorized",
    component: NotAuthorizedPage,
  },
  { path: "/validation", component: TokenVerification },
  {
    path: "/reset-password",
    component: ResetPasswordPage,
  },
  {
    path: "/forgot-password",
    component: ForgotPasswordEmailPage,
  },
  {
    path: "/cabinet",
    component: CabinetLayout,
    requiredRole: ["ROLE_COLLABORATEUR_CABINET", "ROLE_CLIENT_CABINET"],
    children: [
      {
        path: "",
        component: FoldersPage,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
      },
      { path: "dashboard", component: DashboardPage },
      {
        path: "points-suspension",
        component: PointsSuspensionPage,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
      },
      { path: "questionnaire", component: QuestionnairePage },
      {
        path: "test-fec",
        component: FecPage,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
      },
      {
        path: "liste-des-dossiers",
        component: FoldersPage,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
      },
      {
        path: "recherche",
        component: SearchCompanyPage,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
      },
      {
        path: "entreprise",
        component: AddCompanyPage,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
      },
      {
        path: "parametre-fiscal",
        component: FiscalSettingsPage,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
      }, //TODO ajout drawer et fix sa navigation
      {
        path: "assignation-intervenants",
        component: ParticipantsAssignmentPage,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
      }, // Route pour la page Assignations des intervenants (ajouter collaborateur)
      {
        path: "invitation-client",
        component: ClientInviationPage,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
      }, //Route pour la page invitation de client (ajouter client)
      { path: "tableau-de-bord", component: MainDashboard }, //Route pour le tableau de bord principale
      {
        path: "parametre-general",
        component: EditCompanyPage,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
      }, //Route pour l'ajout d'entreprise
      {
        path: "mapping-comptes",
        component: AccountMappingPage,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
      }, //Route pour mapper les comptes
      {
        path: "journal-matching",
        component: JournalMatchingPage,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
      }, //Route pour matcher les journaux
      {
        path: "dossier-questionnaire",
        component: FolderQuestionnairePage,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
      }, //Route pour Questionnaire
      {
        path: "dossier-point-suspens",
        component: FolderPointSuspensPage,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
      }, //Route pour les points en suspens
      {
        path: "parametre-comptabilite",
        component: AccountSettingsLayout,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
        children: [
          { path: "", component: AccountingSettingsPage },
          { path: "journaux", component: AccountingJournalsPage },
          { path: "fiscal", component: FiscalSettingsPage },
          {
            path: "list-comptes-journaux",
            component: AccountListPage,
          }, //Route pour la liste de compte comptabilite journaux
        ],
      },
      {
        path: "production-comptable",
        component: AccountSettingsLayout,
        requiredRole: ["ROLE_COLLABORATEUR_CABINET"],
        children: [
          { path: "", component: PiecesPage },
          { path: "pieces", component: PiecesPage },
          { path: "journal", component: JounalPage },
        ],
      },
    ], //Route pour la page production de comptabilté ,
  },
  {
    path: "/admin",
    component: AdminLayout, // Utilisation de AdminLayout ici
    requiredRole: ["ROLE_ADMIN_APPLICATION"],
    children: [
      { path: "", component: AdminPage },
      { path: "creer-cabinet", component: CreateCabinetPage },
    ],
  },
];
/**
 * @component RouteConfig
 * @description Configuration et rendu des routes avec des rôles basés sur l'authentification
 */
const RouteConfig: FC = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("This must be used within a Company provider");
  }
  const { isAuthenticated } = context;

  return (
    <Suspense fallback={<div>Chargement...</div>}>
      <Routes>
        {/* Redirige la route par défaut vers /auth */}
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="/cabinet" replace />
            ) : (
              <Navigate to="/auth" replace />
            )
          }
        />

        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              route.requiredRole ? (
                <RoleBasedRoute route={route} />
              ) : (
                route.component && <route.component />
              )
            }
          >
            {route.children?.map((child, childIndex) => (
              <Route
                key={childIndex}
                path={child.path}
                element={
                  child.requiredRole ? (
                    <RoleBasedRoute route={child} />
                  ) : (
                    child.component && <child.component />
                  )
                }
              >
                {child.children?.map((childOfChild, childOfChildIndex) => (
                  <Route
                    key={childOfChildIndex}
                    path={childOfChild.path}
                    element={
                      childOfChild.component ? <childOfChild.component /> : null
                    }
                  ></Route>
                ))}
              </Route>
            ))}
          </Route>
        ))}
      </Routes>
    </Suspense>
  );
};

export default RouteConfig;
