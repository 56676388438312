/**
 * @file RoleBasedRoute.tsx
 * @description Ce composant gère l'accès aux routes en fonction du rôle de l'utilisateur.
 * Il redirige les utilisateurs non authentifiés ou sans les rôles requis vers une page de non-autorisation.
 * 
 * @module RoleBasedRoute
 * @component
 * 
 * @returns {JSX.Element | null} - Retourne le composant de la route si l'utilisateur est autorisé, ou redirige vers une page non autorisée.
 * 
 * @example
 * ```tsx
 * import React from 'react';
 * import RoleBasedRoute from './RoleBasedRoute';
 * 
 * const route = {
 *   path: "/admin",
 *   component: AdminPage,
 *   requiredRole: ["ROLE_ADMIN"],
 * };
 * 
 * const App = () => <RoleBasedRoute route={route} />;
 * 
 * export default App;
 * ```
 */

import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext"; // Importation du contexte d'authentification
import { AppRoute } from "./RouteConfig"; // Supposant que AppRoute est le type de route

/**
 * @interface RoleBasedRouteProps
 * @description Interface pour les propriétés du composant `RoleBasedRoute`.
 * @property {AppRoute} route - La route à vérifier, qui contient le composant à afficher et les rôles requis.
 */
interface RoleBasedRouteProps {
  route: AppRoute; // La route à vérifier
}

/**
 * @function RoleBasedRoute
 * @description Composant qui contrôle l'accès aux routes basées sur l'état d'authentification de l'utilisateur
 * et les rôles requis. Si l'utilisateur n'est pas authentifié ou n'a pas les rôles requis, il est redirigé vers
 * une page de non-autorisation.
 * 
 * @param {RoleBasedRouteProps} props - Les propriétés passées au composant, contenant la route à vérifier.
 * @returns {JSX.Element | null} - Retourne le composant de la route si l'utilisateur est autorisé, ou redirige vers une page non autorisée.
 */
const RoleBasedRoute: React.FC<RoleBasedRouteProps> = ({ route }) => {
  const { isAuthenticated, user } = useAuth(); // Récupération de l'état d'authentification et de l'utilisateur
  const location = useLocation(); // Récupération de la localisation actuelle

  console.log("user is:" + JSON.stringify(user));

  // Vérification de l'authentification et des rôles requis
  if (
    !isAuthenticated || // Si l'utilisateur n'est pas authentifié
    (route.requiredRole &&
      !route.requiredRole.some((requiredRole) =>
        user?.roles?.includes(requiredRole)
      )) // Si aucun des rôles requis n'est présent
  ) {
    // Redirige l'utilisateur vers la page "non autorisé" s'il n'a pas les droits requis
    return <Navigate to="/not-authorized" state={{ from: location }} replace />;
  }

  // Rendu du composant si l'utilisateur est autorisé
  const Component = route.component; // Assignation du composant de la route à une variable
  return Component ? <Component /> : null; // Rendu du composant ou null s'il n'existe pas
};

export default RoleBasedRoute;
